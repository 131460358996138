<template>
  <div></div>
</template>

<script>
export default {
    mounted() {
        const key = this.$route.params.key;

        let menu_id = null;
        let cafe_key = null;

        if (key.startsWith('yhc_')) {
            this.$router.push({
                name: 'menu_preview',
                params: {
                    id: 'yeahnot'
                },
                query: {
                    menu_id: '5f6b148d6eb7005e7db148ce',
                    category_ids: [
                        '5f9c290fafb96f32b320a5bc',
                        '5f9d542329d716a02753afa8',
                        '5f9d542329d716a02753afaa',
                        '5f9d542329d716a02753afa9',
                        '5f9d542329d716a02753afab',
                        '5f9d548e24e497ddcec1ec42'
                    ].join(',')
                }
            });
            return;
        }

        if (key == 'mashroom_yeahnot') {
            // menu_id = '60db25de4aa6d63a6953bbd0';
            cafe_key = 'yeahnot';
        } else {
            // menu_id = '60ddc9ac4ec9d67df3dbd8ec';
            cafe_key = 'yizh_dvizh';
        }

        this.$router.push({
            name: 'direct_menu',
            params: {
                code: cafe_key
            },
            // query: {
            //     menu_id
            // }
        });
    }
}
</script>

<style>

</style>